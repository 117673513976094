import React, { useState, useEffect } from 'react';
import Hero from './Hero/Hero';
import About from './About/About';
import Projects from './Projects/Projects';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import ReactGA from 'react-ga';
// import Amplify, { API } from "aws-amplify";
// import AWS from 'aws-sdk/dist/aws-sdk-react'
import Loadable from "@loadable/component"
// import LexChat from "react-lex-plus";

// Initialize the Amazon Cognito credentials provider
// AWS.config.region = 'ap-southeast-2'; // Region
// AWS.config.credentials = new AWS.CognitoIdentityCredentials({
//   IdentityPoolId: 'ap-southeast-2:bc3fed63-42f4-4958-bcc5-dbb89e4f288a',
// });



import { PortfolioProvider } from '../context/context';

import { heroData, aboutData, projectsData, contactData, footerData } from '../mock/data';

function App() {
  const [hero, setHero] = useState({});
  const [about, setAbout] = useState({});
  const [projects, setProjects] = useState([]);
  const [contact, setContact] = useState({});
  const [footer, setFooter] = useState({});
  const Chat = Loadable(() => import("react-lex-plus"))



  useEffect(() => {
    ReactGA.initialize('UA-170119751-1');
    //Report pageview
    ReactGA.pageview('/App');
    setHero({ ...heroData });
    setAbout({ ...aboutData });
    setProjects([...projectsData]);
    setContact({ ...contactData });
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, about, projects, contact, footer }}>

      <Hero />
      <About />

      <Projects />

      <Contact />
      <Footer />
      <Chat
        botName="TalkToMe"
        IdentityPoolId="ap-southeast-2:bc3fed63-42f4-4958-bcc5-dbb89e4f288a"
        placeholder="Type here"
        backgroundColor="#FFFFFF"
        height="430px"
        region="ap-southeast-2"
        headerText="Chat with my awesome bot"
        headerStyle={{
          borderRadius: "5px", backgroundColor: "#7f449d", fontSize: "15px",

        }}
        greeting={
          "Hello, how can I help? "
        }
      />
    </PortfolioProvider>
  );
}

export default App;

import uuidv1 from 'uuid/dist/v1';

// HEAD DATA
export const headData = {
  title: 'Yamin Huzaifa', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Yamin Huzaifa portfolio', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: '',
  subtitle: '',
  cta: '$ cat ~/me ',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    "I'm a polyglot software developer experienced in JavaScript, TypeScript, Python, C#, and Java, known for my dedication, problem-solving skills, and curiosity. I value fresh perspectives and thrive on challenges. I hold a Master's in Information Technology specializing in Software Engineering from RMIT University, Melbourne, with a background in advanced programming, algorithms, and cloud courses",
  paragraphTwo:
    "I have industry experience with React, Next.js, TypeScript, and the ASP.NET framework. I've developed applications using both SQL and NoSQL database systems and have a passion for exploring new development frameworks and tools.",
  paragraphThree: "Beyond coding, I'm enthusiastic about IoT devices.",
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: uuidv1(),
    img: 'cqu1.png',
    title: 'Cental Queensland University public website',
    info:
      'Public website of Central Queensland University with AA accessibility as per WCAG guidlines',
    url: 'https://www.cqu.edu.au/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'mycqu.jpg',
    title: 'Cental Queensland University student portal',
    info:
      'One-stop student-focused app covering all university-related services, including upcoming assessments and submission reminders',
    url: 'https://www.sitecore.com/customers/education/central-queensland-university-best-digital-experience-transformation-2021',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'react.png',
    title: 'REACT.js CRUD',
    info:
      'Personal bill management application built on MERN stack (MongoDB, Express, React and Node). Modern React approaches were used including functional components, Hooks and React-Redux.',
    info2: 'Front-end and Back-end dockerized, running live on aws EC2 instance',
    url: 'http://3.134.98.84:3000/',
    repo: 'https://github.com/Huzi1/MERN_AMD', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'typeGraphQL.png',
    title: 'Typescript GraphQL Client',
    info:
      'Implemented Apollo client for GraphQL in Typescript. Used Apollo InMemorey Cache for state maganement.',
    info2: 'Implemented infinite scroll fetchmore',
    url: 'https://typescript-react-graphql.herokuapp.com',
    repo: 'https://github.com/Huzi1/react-graphqldemo', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'flask.png',
    title: 'Flask(Python) CRUD API with Cloud Firestore',
    info:
      "Simple to do list with server on Python-Flask. Flask API's handling front end request and database operations. Database connection with GCP Firestore ",
    info2: 'Front-end on HTML,CSS and Vanilla.js ',
    url: null,
    repo: 'https://github.com/Huzi1/Flask-TodoList', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'swift.png',
    title: 'Ballin iOS APP',
    info:
      "A basketball app displaying upcoming NBA season games. Data fetched using rest api provided by ballin. Used Apple's core data for storage and CRUD",
    info2: '',
    url: null,
    repo: 'https://github.com/Huzi1/ios_Swift', // if no repo, the button will not show up
  },
  {
    id: uuidv1(),
    img: 'compose_swarm.png',
    title: 'Inventory management microservices',
    info:
      "Sample application using docker-compose for database services.",
    info2: ' This application is build on Python and React. Postgress and MongoDB services deployed using docker-compose.',
    url: null,
    repo: 'https://github.com/Huzi1/packform', // if no repo, the button will not show up
  },
];

// CONTACT DATA Public Static getMe(string contact){...}
export const contactData = {
  cta: '',
  btn: 'def get_Me():',
  email: 'huzaifayamin11@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: uuidv1(),
      name: 'stack-overflow',
      url: 'https://stackoverflow.com/users/13069003/huzaifa-yamin',
    },
    // {
    //   id: uuidv1(),
    //   name: 'twitter',
    //   url: '',
    // },
    {
      id: uuidv1(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/yaminhuzaifa/',
    },
    {
      id: uuidv1(),
      name: 'github',
      url: 'https://github.com/Huzi1',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
